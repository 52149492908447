(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name neo.home.seasons.season.controller:SeasonCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.seasons.season')
  .controller('SeasonCtrl', SeasonCtrl);

  function SeasonCtrl() {
    var vm = this;
    vm.ctrlName = 'SeasonCtrl';
  }
}());
